/*===================== 
	Premery bg color 
=====================*/
:root {
	--bg-color: #94BCF9;
	--bg-color-ho: #94BCF9;
}

/* Hex */
.meet-ask-row:after,
.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before,
.dlab-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.widget-title:after,
.site-button,
.comments-area .comment-form p input[type="submit"],
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active>[class*="site-button"],
.list-num-count>li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.skew-angle:after,
.header-style-4.style-1 .header-nav .nav>li.active a:before,
.header-style-4.style-1 .header-nav .nav>li:hover>a:before,
.dlab-project-left:after,
.service-carousel .owl-prev,
.service-carousel .owl-next,
.about-us .dlab-tabs .nav-tabs li a.active,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.center.active .testimonial-text,
.icon-bx-wraper.hover:hover,
.portfolio-sep,
.portfolio-ic .dlab-img-overlay1:before,
.dlab-box-bg.active .icon-box-btn .site-button,
.dlab-accordion.primary .acod-head a,
.side-bar .widget_tag_cloud a:hover,
.time-line:after,
.line-right:after,
.line-left:after,
.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover,
.tabs-site-button .nav-tabs li a.active,
.box-item-service:hover .item-service-content,
.box-item-service:hover h5,
.box-item-service:hover .icon-bx-md,
.tp-caption.rev-btn.tc-btnshadow.tp-rs-menulink,
.la-anim-10 {
	background-color: #94BCF9;
}

/*Hex color :hover */
.site-button:active,
.site-button:hover,
.site-button:focus,
.active>.site-button,
.bg-primary-dark,
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus,
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover,
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover,
.owl-btn-2.primary .owl-next:hover {
	background-color: #94BCF9;
}

.about-us .dlab-tabs .nav-tabs li a.active {
	border-right: 4px solid #94BCF9;
}


/* Rgba color */
.dlab-info-has.bg-primary,
.dlab-info-has.bg-secondry:hover,
.tp-bannertimer.tp-bottom {
	background-color: rgb(148, 188, 249, 0.9);
}

/* Box Shadow */
.side-bar .widget_tag_cloud a:hover {
	box-shadow: 5px 5px 10px 0 rgba(108, 192, 0, 0.15);
}

/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.header-nav .nav>li.active>a,
.header-nav .nav>li.current-menu-item>a .header-nav .nav>li .sub-menu li a:hover,
.header-nav .nav>li:hover>a,
.header-nav .nav>li .mega-menu>li ul a:hover,
.header-nav .nav>li .sub-menu li:hover>a,
.nav-dark.header-nav .nav>li .sub-menu li:hover>a,
.nav-dark.header-nav .nav>li .mega-menu>li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dlab-tabs .nav-tabs>li>a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.site-button.outline,
.site-button-link:hover,
.item-info-in ul li a:hover,
.dlab-post-meta.text-primary i,
.dlab-post-meta.text-primary ul li,
.dlab-post-meta.text-primary ul li a,
.dlab-post-meta i,
.comments-area p:before,
.about-8-service .icon-bx-wraper:hover a,
.about-service .icon-bx-wraper:hover a,
.dlab-box-bg .icon-box-btn .site-button,
.list-2 li:hover:before {
	color: #94BCF9;
}

@media only screen and (max-width: 991px) {

	.nav-dark.header-nav .nav>li>a:hover,
	.nav-dark.header-nav .nav>li>a:active,
	.nav-dark.header-nav .nav>li>a:focus {
		color: #94BCF9;
	}
}

/*===================== 
	Premery border color 
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
blockquote,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type="checkbox"]:checked+label:before,
input[type="checkbox"]+label:hover:before,
input[type="radio"]+label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover {
	border-color: #94BCF9;
}

.owl-item.active.center .testimonial-8 .testimonial-text:after {
	border-top: 15px solid #94BCF9;
}

.dlab-tabs.border-top .nav-tabs>li>a.active,
.header-nav .nav>li .sub-menu,
.header-nav .nav>li .mega-menu {
	border-top-color: #94BCF9;
}

.port-style1 .port-box {
	border-left: 3px solid #94BCF9;
}

.choseus-tabs .nav li a.active {
	border-bottom: 2px solid #94BCF9;
}

svg.radial-progress:nth-of-type(6n+1) circle {
	stroke: #94BCF9;
}

/*===================== 
	Secondery bg color
 =====================*/
.bg-secondry {
	background-color: #2d3239;
}

/*===================== 
	Secondery text color
 =====================*/
.text-secondry {
	color: #2d3239;
}

.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.overlay-primary:after,
.bg-primary {
	background-color: #0f1706;
}

.team-section .team-content,
.tp-overlay-video {
	background-color: rgba(15, 23, 6, 0.7);
}

/* Css Gradient */
.tab-market ul li a:after,
.tab-bx,
.site-button.btn-icon i,
.footer-gradient,
.overlay-primary.gradient:after,
.tp-overlay-pera,
.home-banner:after,
.overlay-primary.testimonial-curv-bx:after,
.about-progress .progress-bar,
.pricing-bx .pricingtable-wrapper.style1.active,
.contact-form-bx .input-group:after,
.workspace-bx .dlab-img-overlay1:before {
	background: rgba(15, 23, 6, 0.9);
}

.new-page {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b3ba00+0,68b800+100 */
	background: rgb(179, 186, 0);
	/* Old browsers */
	background: -moz-linear-gradient(45deg, rgba(179, 186, 0, 0.9) 0%, rgba(104, 184, 0, 0.9) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, rgba(179, 186, 0, 0.9) 0%, rgba(104, 184, 0, 0.9) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, rgba(179, 186, 0, 0.9) 0%, rgba(104, 184, 0, 0.9) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3ba00', endColorstr='#68b800', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.arrow-bx {
	background-image: url(../../images/arrow-1.png);
}

.owl-btn-1.primary .owl-prev,
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev,
.owl-btn-2.primary .owl-next {
	background-color: #94BCF9 !important;
}